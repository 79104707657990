<template>
  <v-app>
    <TopBar app bar-type="not-found" />
    <v-main>
      <div class="text-center mt-16">
        <v-img src="@/assets/images/404.svg" width="500" style="margin: 0 auto"></v-img>
        <h1 class="my-8">Not Found</h1>
        <AppButton to="/" color="secondary">Go To Home</AppButton>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from '@/components/TopBar.vue';

export default {
  name: 'NotFound',
  components: {
    TopBar,
  },
};
</script>

