var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("TopBar", { attrs: { app: "", "bar-type": "not-found" } }),
      _c("v-main", [
        _c(
          "div",
          { staticClass: "text-center mt-16" },
          [
            _c("v-img", {
              staticStyle: { margin: "0 auto" },
              attrs: { src: require("@/assets/images/404.svg"), width: "500" },
            }),
            _c("h1", { staticClass: "my-8" }, [_vm._v("Not Found")]),
            _c("AppButton", { attrs: { to: "/", color: "secondary" } }, [
              _vm._v("Go To Home"),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }